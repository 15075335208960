(function (window) {
  const isPrivacyShown = window.localStorage.getItem('uxfeedbackPrivacy');

  if (isPrivacyShown) {
    return;
  }

  const handleClick = (e) => {
    e.preventDefault();
    window.localStorage.setItem('uxfeedbackPrivacy', true);
    document.querySelector('.cookies-container').classList.remove('active');
  }

  setTimeout(() => {
    document.querySelector('.cookies-container').classList.add('active');
    document.querySelector('.cookies-container .button').onclick = handleClick;
  }, 1000);
})(window);
